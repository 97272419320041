import { ISignerControlDataModal } from "src/model/Esign/ISignerControlDataModal";
import { ISignAsyncRequestModel } from "src/model/ViewModel/RequestModels";
import * as moment from 'moment-timezone'

export const EMPTY_STRING = "";

export const hideFinishButton = () => { 
    let element = document.querySelector('[data-shepherd-step-id="awesome-multi-steps-btn-next"]'); 
        element?.setAttribute("hidden", "true");
}

export const PrepareSignAsyncRequestModel = (signatureData: ISignerControlDataModal) => {

    const signAsyncRequestModel : ISignAsyncRequestModel = {
        clientGuid: signatureData.clientGuid,
        signature: signatureData.signature,
        documentData: signatureData.documentData,
        signatureInitial: signatureData.signatureInitial,
        userTimeZone: moment.tz.guess(true)
    }
    return signAsyncRequestModel;
}