import { RouteSelector } from "./RouteSelector";
import { ApplicationState } from '../../../reducers';
import { connect } from 'react-redux';
import { IClientSignatureStatus } from '../../../model/ViewModel/ClientSignatureStatus';
import { requestSignatureInfo } from '../Sign/Esign/Action/EsignActions';
import {
    refreshToken, requestOTP, validateOTP, validateQnA, validateSSN, getQnAQuestion,
    requestMobileOTP, validateMobileOTP } from '../Login/Action/LoginActions';
import { getTokenForAnonymous, requestStates } from "../signflow/Action/SignerSignFlowActions";
import { History } from "history";

const mapStateToProps = (state: ApplicationState) => ({
    primaryDetailsStore: state.primaryDetails,
    tokenInfo: state.tokenInfo,
    primaryTokenInfo: state.primaryTokenInfo,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestSignatureInfo: (clientId: string, successCallback: (status: IClientSignatureStatus, DataService: any, ignoreStatus: boolean) => void) =>
            dispatch(requestSignatureInfo(clientId, successCallback)),

        requestOTP: (clientId: string, successCallback?: () => void) =>
            dispatch(requestOTP(clientId, successCallback)),

        validateOTP: (clientId: string, otp: string, successCallback: any, history: History) =>
            dispatch(validateOTP(clientId, otp, successCallback, history)),

        refreshToken: (clientId: string, successCallback?: () => void) =>
            dispatch(refreshToken(clientId, successCallback)),

        getTokenForAnonymous: (Id: string, successCallback: (clientId: string) => void) => dispatch(getTokenForAnonymous(Id, successCallback)),

        validateSSN: (clientId: string, ssn: string, successCallback: any, history:History) =>
             dispatch(validateSSN(clientId, ssn, successCallback, history)),

        validateQnA: (clientId: string, answer: string, successCallback: any, history:History) => 
            dispatch(validateQnA(clientId, answer, successCallback, history)),

        getQnAQuestion: (clientId: string, successCallback: any) => dispatch(getQnAQuestion(clientId, successCallback)),

        requestMobileOTP: (clientId: string, successCallback?: () => void) =>
            dispatch(requestMobileOTP(clientId, successCallback)),

        validateMobileOTP: (clientId: string, otp: string, successCallback: any, history:History) =>
            dispatch(validateMobileOTP(clientId, otp, successCallback, history)),

        requestStates: (clientId: string, successCallback: any) => dispatch(requestStates(clientId, successCallback)),
    }
}

export const RouteSelectorContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RouteSelector);
