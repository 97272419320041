import { actionTypes } from "../../../../common/constants/actionTypes";
import { IDataService } from "../../../../core/services/dataAccess/abstraction/IDataService";
import { container } from "../../../../core/startup/inversify/inversify.config";
import { TYPES } from "../../../../core/startup/inversify/types";
import { ILoader } from "../../../../core/utilities/ui/Loader";
import SignerHelper from "../Helper/SignerHelper";
import { unloadedprimaryInfoState, IPublicData, AuthenticationType } from "../Helper/SignerSignFlowHelper";
import { IResponseNotifier } from "../../../../model/ViewModel/IResponseNotifier";
import { NotificationType } from "../../../../model/Common/Enums";
import { storeTokenInMemory } from "../../../../core/services/dataAccess/DataService.Axios";
import { ILocalStore } from "../../../../core/utilities/LocalStore";
import { history } from "../../../../core/services/dataAccess/History";
import { PathConstants } from "../../../../common/constants/Constants";

const dataService = container.get<IDataService>(TYPES.IDataService);
const loader = container.get<ILoader>(TYPES.ILoader);
const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export const requestPrimaryDetails = (Id: string, successCallback?: (data : any) => void) => (dispatch: any) => {
    //const clientId: string = SignerHelper.getClientId();

    loader.show();
    dispatch({
        type: actionTypes.REQUEST_PRIMARY_INFO,
        data: true
    });
    dataService.get('api/Public/GetPrimaryDetails/' + Id).then(function (response: any) {
        if (response.data.authenticationInfo == null) {
            localStore.remove('loggedIn');
            history.push(`${PathConstants.Invalid}`);
        }
        //if (response.data.isOtpRequired) {
        //    response.data.authenticationInfo.authenticationType = AuthenticationType.AccessCodeEmail;
        //}
        dispatch({
            type: actionTypes.RESPONSE_PRIMARY_INFO,
            data: response.data
        });
        loader.hide();
        successCallback && successCallback(response.data);
    }).catch(function (error: any) {
        loader.hide();
    });
}

export const getTokenForAnonymous = (Id: string, successCallback: (clientId: string) => void) => (dispatch: any) => {
    //const clientId: string = SignerHelper.getClientId();

    loader.show();
    dispatch({
        type: actionTypes.REQUEST_PRIMARY_INFO,
        data: true
    });
    dataService.get('api/Anonymous/GenerateToken/' + Id).then(function (response: any) {
        const result: IResponseNotifier = response.data;
        if (result.Type === NotificationType.Success) {
            dispatch({
                type: actionTypes.RECEIVE_PUBLIC_USER_ACCESSTOKEN, accessToken: result.Data.Token
            });
            storeTokenInMemory(result.Data.ClientGuid, result.Data.Token);
            localStore.set("loggedIn", "true");
            loader.hide();
            successCallback(result.Data.ClientGuid);
        }
        else {
            loader.hide();
        }

    }).catch(function (error: any) {
        loader.hide();
    });
}

export const requestStates = (Id: string, successCallback?: () => void) => (dispatch: any) => {
    //const clientId: string = SignerHelper.getClientId();

    loader.show();
    dataService.get('api/Public/GetAllStates/' + Id).then(function (response: any) {
        dispatch({
            type: actionTypes.RECEIVE_STATES,
            data: response.data
        });
        loader.hide();
        successCallback && successCallback();
    }).catch(function (error: any) {
        loader.hide();
    });
}
export const  updateLockState = (isLocked: boolean) => (dispatch: any) => {
    dispatch({
        type: actionTypes.UPDATE_LOCKED_STATUS, isLocked: isLocked
    });
}

export interface IPublicUserTokenData {
    token: string;
    isLoading: boolean;
}

const unloadedTokenState: IPublicUserTokenData = {
    token: "",
    isLoading: false
} as IPublicUserTokenData;


export const primaryDetailsReducer = (state: IPublicData = unloadedprimaryInfoState, action: any) => {

    switch (action.type) {

        case actionTypes.RESPONSE_PRIMARY_INFO: {
            const newState = { ...state };
            newState.primaryDetails = action.data;
            newState.isLoading = false;
            return newState;
        }

        case actionTypes.REQUEST_PRIMARY_INFO: {
            const newState = { ...state };
            newState.isLoading = action.data;
            return newState;
        }

        case actionTypes.RECEIVE_STATES: {
            const newState = { ...state };
            newState.states = action.data;
            return newState;
        }
        case actionTypes.UPDATE_LOCKED_STATUS:
            const newState = { ...state };
            newState.primaryDetails.authenticationInfo.isLocked = action.isLocked;
            newState.primaryDetails.authenticationInfo.lockedOn = new Date();
            return newState;
    }

    return state || unloadedprimaryInfoState;
};


export const primarytokenReducer = (state: IPublicUserTokenData = unloadedTokenState, action: any) => {

    switch (action.type) {

        case actionTypes.RECEIVE_PUBLIC_USER_ACCESSTOKEN: {
            return {
                token: action.accessToken,
                isLoading: false
            } as IPublicUserTokenData;
        }
    }

    return state || unloadedTokenState;
};

