import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IPublicData } from '../../signflow/Helper/SignerSignFlowHelper';
import { ISignerData } from '../../Sign/Esign/Action/EsignActions';
import { IClientSignatureStatus } from '../../../../model/ViewModel/ClientSignatureStatus';

let moment = require('moment');

export type LockedProps =
    {
        primaryDetailsStore: IPublicData;
        signatureInfoStore: ISignerData;
        requestSignatureInfo: (clientId: string, callback: (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => void) => void;
        refreshToken: (clientId: string, callback?: () => void) => void;
    }
    & RouteComponentProps<{}>;

export const Locked: React.FunctionComponent<LockedProps> = (props) => {
    const param: any = props.match.params;
    const [isLocked, setIsLocked] = useState(true);

    const handleGoHomeClick = () => {
        props.history.push('/login/' + param.Id);
    };

    const utcDateTime = moment.utc();
    const lockedOnTime = props.primaryDetailsStore.primaryDetails?.authenticationInfo?.lockedOn 
        ? moment.utc(props.primaryDetailsStore.primaryDetails.authenticationInfo.lockedOn)
        : null;
    const canUnlock = lockedOnTime && utcDateTime.isAfter(lockedOnTime.add(10,'minutes'))? true:false;
    useEffect(() => {
        if (canUnlock) {
            setIsLocked(false);
        }
    }, [canUnlock]);


    return (
        <React.Fragment>
            <div id="content-wrapper" className="col-xs-12 content-wrapper-scroll">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ marginTop: "35px" }}>
                    <div className="greeting text-left" style={{ width: "100%" }}>
                        <h5 style={{ fontWeight: 600 }}>Document Locked!</h5>
                        <div className="font16" style={{ marginTop: '5%' }}>
                            The access link has been locked.<br />
                            Please try again in ten minutes or contact your firm for assistance.
                        </div>
                        <button
                            onClick={handleGoHomeClick}
                            disabled={isLocked}
                            style={{marginLeft: '30%',marginTop: '5%'}}
                        >
                            Go Home
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
